import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import isEqual from 'lodash/isEqual'
import { RootState } from '~/store'
import { filtersList, getInitialState } from '~/core/config/filters/amp/mobile.config'
import { AmpMobileFiltersState as MobileFiltersState } from '~/core/config/filters/types'

export const namespace = 'modules/Amp/Filters/Mobile'

export const ActionType = {
  INIT: 'INIT',
  RESET_FILTER: 'RESET_FILTER',
}

export const MutationType = {
  SET_FILTER: 'SET_FILTER',
}

export const GettersType = {
  GET_FILTERS: 'GET_FILTERS',
  IS_FILTERS_AT_INITIAL_STATE: 'IS_FILTERS_AT_INITIAL_STATE',
}

export const state = (): MobileFiltersState => getInitialState()

export const getters: GetterTree<MobileFiltersState, RootState> = {
  [GettersType.GET_FILTERS] (state: MobileFiltersState): MobileFiltersState {
    return state
  },
  [GettersType.IS_FILTERS_AT_INITIAL_STATE] (state: MobileFiltersState, _getters, _rootState): boolean {
    const initState = getInitialState()
    return isEqual(initState, state)
  },
}

export const actions: ActionTree<MobileFiltersState, RootState> = {
  [ActionType.INIT] ({ state, rootGetters, commit }): void {
    if (state.providers.length === 0) {
      const initialProviders = filtersList(rootGetters)
        .find(filter => filter.name === 'providers')?.props.defaultValue as string[] || []

      if (initialProviders.length) {
        commit(MutationType.SET_FILTER, {
          key: 'providers',
          value: initialProviders,
        })
      }
    }
  },
  [ActionType.RESET_FILTER] (context): void {
    const initial = filtersList(context.rootGetters)
    initial.forEach((filter) => {
      context.commit(MutationType.SET_FILTER, {
        key: filter.name,
        value: filter.props.defaultValue,
      })
    })
  },
}

export const mutations: MutationTree<MobileFiltersState> = {
  [MutationType.SET_FILTER] (state: MobileFiltersState, payload: {key: keyof typeof state, value: typeof state[keyof typeof state]}): void {
    switch (payload.key) {
      case 'providers':
      case 'data_options':
      case 'call_options': {
        if (!Array.isArray(payload.value)) {
          throw new TypeError('value must be a string[]')
        }
        state[payload.key] = payload.value
        break
      }
      case 'five_g_only': {
        if (typeof payload.value !== 'boolean') {
          throw new TypeError(`value of filter ${payload.key} must be a boolean`)
        }
        state[payload.key] = payload.value
        break
      }
      default: {
        throw new Error('unknown filter name: ' + payload.key)
      }
    }
  },
}
