import type { ActionTree, GetterTree, MutationTree } from 'vuex'
import isEqual from 'lodash/isEqual'
import { RootState } from '~/store'
import { filtersList, getInitialState } from '~/core/config/filters/amp/home.config'
import { AmpHomeFiltersState as HomeFiltersState } from '~/core/config/filters/types'

export const namespace = 'modules/Amp/Filters/Home'

export const ActionType = {
  INIT: 'INIT',
  RESET_FILTER: 'RESET_FILTER',
}

export const MutationType = {
  SET_FILTER: 'SET_FILTER',
}

export const GettersType = {
  GET_FILTERS: 'GET_FILTERS',
  IS_FILTERS_AT_INITIAL_STATE: 'IS_FILTERS_AT_INITIAL_STATE',
}

export const state = (): HomeFiltersState => getInitialState()

export const getters: GetterTree<HomeFiltersState, RootState> = {
  [GettersType.GET_FILTERS] (state: HomeFiltersState): HomeFiltersState {
    return state
  },
  [GettersType.IS_FILTERS_AT_INITIAL_STATE] (state: HomeFiltersState, _getters, _rootState): boolean {
    const initState = getInitialState()
    return isEqual(initState, state)
  },
}

export const actions: ActionTree<HomeFiltersState, RootState> = {
  [ActionType.INIT] ({ state, rootGetters, commit }): void {
    if (state.providers.length === 0) {
      const initialProviders = filtersList(rootGetters)
        .find(filter => filter.name === 'providers')?.props.defaultValue as string[] || []

      if (initialProviders.length) {
        commit(MutationType.SET_FILTER, {
          key: 'providers',
          value: initialProviders,
        })
      }
    }
  },
  [ActionType.RESET_FILTER] (context): void {
    const initial = filtersList(context.rootGetters)
    initial.forEach((filter) => {
      context.commit(MutationType.SET_FILTER, {
        key: filter.name,
        value: filter.props.defaultValue,
      })
    })
  },
}

export const mutations: MutationTree<HomeFiltersState> = {
  [MutationType.SET_FILTER] (state: HomeFiltersState, payload): void {
    state[payload.key as keyof typeof state] = payload.value
  },
}
