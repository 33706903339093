import type { ProviderModel } from '@alao-frontend/erp-services'
import { FiltersList, AmpHomeFiltersState as HomeFiltersState } from '~/core/config/filters/types'

export const getInitialState = (): HomeFiltersState => {
  return {
    providers: [],
    household_size: '',
    data_speed: [],
    home_call_options: [],
    tv_options: [],
  }
}

export const filtersList = (
  rootGetters: any,
): FiltersList => {
  const defaultValues = getInitialState()

  return [
    {
      name: 'providers', // key in initialState
      component: 'TileSelection',
      props: {
        title: 'offerFilters.providers',
        expandableLimit: 6,
        isExpandable: true,
        defaultValue: defaultValues.providers,
        items: rootGetters['modules/BaseInfo/homeProviders'].map((e: ProviderModel) => {
          return {
            name: e.title,
            extra: {
              logo: e.logo?.original,
            },
            value: e.slug,
            slug: e.slug,
          }
        }),
      },
    },
    {
      name: 'household_size', // key in initialState
      component: 'SingleTileSelection',
      props: {
        title: 'offerFilters.dataSpeed',
        subTitle: 'offerFilters.howManyPeopleLiveInYourHousehold',
        defaultValue: defaultValues.household_size,
        items: [
          {
            name: 'offerFilters.single',
            extra: {
              logo: require('~/assets/icons/icon-single.svg'),
            },
            value: 'single',
          },
          {
            name: 'offerFilters.couple',
            extra: {
              logo: require('~/assets/icons/icon-couple.svg'),
            },
            value: 'pair',
          },
          {
            name: 'offerFilters.family',
            extra: {
              logo: require('~/assets/icons/icon-family.svg'),
            },
            value: 'family',
          },
        ],
      },
    },
    {
      name: 'data_speed', // key in initialState
      component: 'MultipleSelection',
      props: {
        subTitle: 'offerFilters.howYouMakeUseOfPlan',
        defaultValue: defaultValues.data_speed,
        items: [
          {
            name: 'offerFilters.playingGames',
            value: 'gaming',
          },
          {
            name: 'offerFilters.streamVideos',
            value: 'netflix',
          },
          {
            name: 'offerFilters.socialMedia',
            value: 'social_media',
          },
          {
            name: 'offerFilters.workFromHome',
            value: 'work_from_home',
          },
        ],
      },
    },
    {
      name: 'home_call_options', // key in initialState
      component: 'MultipleSelection',
      props: {
        title: 'offerFilters.callOptions',
        defaultValue: defaultValues.home_call_options,
        items: [
          {
            name: 'offerFilters.unlimitedCallsInCH',
            value: 'unlimited_calls_in_ch',
          },
          {
            name: 'offerFilters.callsFromCHToEU',
            value: 'unlimited_calls_from_ch_to_eu',
          },
        ],
      },
    },
    {
      name: 'tv_options', // key in initialState
      component: 'MultipleSelection',
      props: {
        title: 'offerFilters.tvOptions',
        defaultValue: defaultValues.tv_options,
        items: [
          {
            name: 'offerFilters.basicChannels',
            value: 'has_tv_channels',
          },
          {
            name: 'offerFilters.premiumChannels',
            value: 'has_tv_channels_hd',
          },
        ],
      },
    },
  ]
}
